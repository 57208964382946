<template>
  <window-content>
    <div class="wrapper-sm">
      <form id="leilaoFilter" class="m-t-sm">
        <e-row class="def-m-r">

          <e-col style="max-width: 110px; min-width: 60px">
            <erp-s-field
                view="tl"
                label="ID"
            >
              <erp-input shortkey="F3" v-model="table.filters.id" />
            </erp-s-field>
          </e-col>

          <div class="col" style="max-width: 110px; min-width: 110px">
            <erp-s-field
                view="tl"
                label="Código do Leilão"
            >
              <erp-input shortkey="F2" v-model="table.filters.codigo" />
            </erp-s-field>
          </div>

          <div class="col" style="min-width: 126px;">
            <tipo-leilao-select v-model="table.filters.tipo"/>
          </div>

          <div class="col" style="min-width: 146px; max-width: 146px">
            <default-async-select label="Classificação" v-model="table.filters.classificacao"
                                  :list="listClassificacao"/>
          </div>

          <div class="col" style="min-width: 48%;">
            <date-input label="Data (De)" v-model="table.filters.data1"/>
          </div>

          <div class="col" style="min-width: 48%;">
            <date-input label="Data (Até)" v-model="table.filters.data2"/>
          </div>

          <div class="col" style="min-width: 48%">
            <default-async-select label="Leiloeiro" v-model="table.filters.leiloeiro" :list="listLeiloeiros"/>
          </div>

          <div class="col" style="min-width: 48%">
            <erp-s-field
                view="tl"
                label="Descrição"
            >
              <erp-input shortkey="F4" v-model="table.filters.descricao" />
            </erp-s-field>
          </div>

          <div class="col" style="min-width: 48%">
            <status-select multiple v-model="table.filters.status"/>
          </div>

          <div class="col" style="min-width: 48%">
            <status-interno-select multiple v-model="table.filters.statusInterno"/>
          </div>

        </e-row>
      </form>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="load('html')" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {datePtToEn} from "@/utils/date"
import StatusSelect from "@/components/leiloes/components/include/StatusLeilaoSelect"
import {donwloadFile} from "@/utils/downloadFile"
import TipoLeilaoSelect from '@/components/leiloes/components/include/TipoLeilaoSelect'
import DefaultAsyncSelect from '@/components/leiloes/components/../../../reuse/input/DefaultAsyncSelect'
import StatusInternoSelect from '@/components/leiloes/components/include/StatusInternoLeilaoSelect'
import {relatorioLeiloesCompelto, listClassificacao, listLeiloeiros} from "@/domain/leiloes/services"
import leiloesHtmlView from "@/components/relatorios/components/leiloes/leiloesHtmlView"

let filters = {
  id: null,
  descricao: null,
  codigo: null,
  tipo: null,
  classificacao: null,
  data1: null,
  data2: null,
  leiloeiro: null,
  status: [],
  statusInterno: []
}

export default {
  name: 'RelatorioLotesWindow',
  mixins: [mixinPrint],
  props: {
  },
  data() {
    return {
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    leiloesHtmlView: leiloesHtmlView,
    request({pagination, filter, format}) {

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.codigo && extraFilters.push(`&codigo=${this.table.filters.codigo}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.classificacao && extraFilters.push(`&classificacao=${this.table.filters.classificacao}`)
      this.table.filters.leiloeiro && extraFilters.push(`&leiloeiro=${this.table.filters.leiloeiro}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      if (Array.isArray(this.table.filters.statusInterno) && this.table.filters.statusInterno.length > 0) {
        extraFilters.push(`&statusInterno=${this.table.filters.statusInterno.join(',')}`)
      }

      if (format === 'excel' || format === 'xlsx') {
        extraFilters.push('&format=excel')
      } else if(format === 'html') {
        extraFilters.push('&format=html')
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataProximoLeilao'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&search=${filter}${extraFilters.join('')}`)


      relatorioLeiloesCompelto(pagination.rowsPerPage, pagination.page, filtros, format)
          .then((response) => {
            let data = response.data
            this.loading = false
            this.table.loading = false
            if (format === 'excel' || format === 'xlsx') {
              donwloadFile(response)
              return
            } else if(format === 'html') {
              this.leiloesHtmlView(data)
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            let result = data.result
            if (Number(pagination.page) < 2) {
              if (data.hoje && data.hoje.length) {
                data.hoje.map(leilao => {
                  leilao.isToday = true
                  result = result.filter(l => l.id !== leilao.id)
                  result.unshift(leilao)
                })
              }
            }
            this.table.serverData = result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca,
        format: format
      })
    },
    listClassificacao: listClassificacao,
    listLeiloeiros: listLeiloeiros
  },
  components: {
    StatusSelect,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    // URadio
    // ErpSelect,
    DefaultAsyncSelect,
    TipoLeilaoSelect,
    StatusInternoSelect
  }
}
</script>
